<template>
  <div id="app">
    <OfficialWebsite></OfficialWebsite>
  </div>
</template>

<script>
import OfficialWebsite from '@/views/OfficialWebsite'
export default {
  components:{OfficialWebsite},
  name: 'App',
}
</script>

<style scoped>
</style>
