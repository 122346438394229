<template>
<div class="home">
  <div class="header-wrap">
    <div class="logo-wrap">
      <img :src="logo" alt="">
      <div class="logo-r">
        <div></div>
        <div>
          <span>来元造享</span>
          <span>专属衣柜</span>
        </div>
      </div>
    </div>
    <div class="customized-btn">
      <span @click="getCustomized()">开始定制</span>
    </div>
  </div>
  <div class="banner-wrap">
    <img :src="firstImg" alt="">
    <span>Come to yuanzao and enjoy the Exclusive wardrobe</span>
    <div>
      <i>"</i>
      <span>
        元造定制
      </span>
      <span>
        更多年轻人选择的
      </span>
      <span>
        服装定制平台
      </span>
    </div>
    <div @click="getCustomized()">
      <span>开启定制之旅</span>
      <img :src="arrowImg" alt="">
    </div>
  </div>
  <div class="article-wrap">
    <div class="menswear-wrap">
      <img :src="boyByArticle" alt="">
     <div>
       <span>【不仅是衣物，更是生活态度】</span>
       <span>
         在[元造]，定制不仅仅是一件衣物那么简单，它是一种对美好生活的追求，一种对自我风格的坚持，一种对品质生活的诠释。让我们携手，为您量身打造那份独一无二的魅力与自信。
      </span>
     </div>
    </div>
    <div class="woman-wrap">
     <div>
       <span>【风格多样，引领潮流】</span>
       <span>
         无论是经典复古、现代简约，还是浪漫优雅、前卫时尚，[元造]都能精准捕捉您的风格密码，设计出既符合当下潮流又不失个人特色的服装款式。让您的每一次亮相，都成为不可复制的风景线。
      </span>
     </div>
      <img :src="womanByArticle" alt="">
    </div>
    <div>
      <img :src="commonImg" alt="">
    </div>
  </div>
  <div class="exclusive-wrap">
    <div class="exclusive-header">
      <span>Come to yuanzao and enjoy the Exclusive wardrobe</span>
      <img :src="more" alt="">
    </div>
    <div class="exclusive-middle">
      <div>
        <span>【匠心独运，量身打造】</span>
        <span>欢迎莅临元造定制服装官网，这里不仅是衣物的诞生地，更是您个性与品味的璀璨舞台。我们相信，每一件衣物都应如指纹般独一无二，承载着穿着者的故事与态度。从面料甄选到剪裁缝制，每一步都凝聚着匠人的心血与智慧。元造携手资深设计师与资深裁缝师，采用一对一专属服务模式，根据您的身形特点、风格偏好及场合需求，精心打造专属于您的服装艺术品。</span>
      </div>
      <img :src="peoplePic" alt="">
    </div>
    <div class="exclusive-footer">
      <img :src="portrait" alt="">
      <div>
        <span>元造定制</span>
        <img :src="customized" alt="">
      </div>
    </div>
  </div>
  <div class="img-wrap">
    <img :src="top" alt="">
    <img :src="bottom" alt="">
  </div>
  <div class="headerData-wrap">
    <img :src="logoByFooter" alt="">
    <div>
      <div>
        <span>联系我们</span>
        <span>客服24小时在线</span>
        <span>来元造，享专属衣柜</span>
      </div>
      <div>
        <span>互动平台</span>
        <span>关注我们</span>
        <span>来元造，享专属衣柜</span>
      </div>
    </div>
  </div>
  <el-dialog
      :visible.sync="isCustomizedDialog"
      width="30%"
      :before-close="handleClose"
      class="customized-dialog"
  >
    <div class="scan-wrap">
      <span>立即下载</span>
      <img :src="scan" alt="">
    </div>
    <span slot="footer" class="dialog-footer">
  </span>
  </el-dialog>
</div>
</template>
<script>

import firstImg from '@/assets/images/firstImg.png'
import arrowImg from '@/assets/images/arrow.png'
import logo from '@/assets/images/logo.jpg'
import boyByArticle from '@/assets/images/boyByArticle.png'
import womanByArticle from '@/assets/images/womanByArticle.png'
import commonImg from '@/assets/images/commonImg.png'
import more from '@/assets/images/more.png'
import peoplePic from '@/assets/images/peoplePic.png'
import portrait from '@/assets/images/portrait.png'
import customized from '@/assets/images/customized.png'
import top from '@/assets/images/top.png'
import bottom from '@/assets/images/bottom.png'
import logoByFooter from '@/assets/images/logoByFooter.png'
import scan from '@/assets/images/scan.png'
export default {
  name: "OfficialWebsite",
  data(){
    return{
      isCustomizedDialog:false,
      scan,
      logoByFooter,
      bottom,
      top,
      customized,
      portrait,
      peoplePic,
      more,
      arrowImg,
      firstImg,
      logo,
      boyByArticle,
      womanByArticle,
      commonImg
    }
  },
  methods:{
    goToAppStore() {
      const url = `https://apps.apple.com`;
      window.location.href = url;
    },
    downloadApk() {
      // 这里可以添加下载时的逻辑，比如统计下载次数，或者显示下载中的提示等
      console.log('开始下载 APK...');
    },
    handleClose(){
      this.isCustomizedDialog=false
    },
    getCustomized(){
      this.isCustomizedDialog=true
    }
  }
}
</script>

<style scoped lang="scss">
.customized-dialog{
  ::v-deep{
    .el-dialog__header{
      .el-dialog__headerbtn{
        .el-dialog__close{
          font-size:20px;
        }
      }
    }
  }
}
.scan-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  span{
    font-size:36px;
    font-weight: bold;
    color: #000000;
  }
  img{
    margin-top: 20px;
    width: 272px;
    height: 274px;
  }
}
.headerData-wrap{
  height: 429px;
  background: #000;
  display: flex;
  align-items: center;
  padding-left: 120px;
  box-sizing: border-box;
  margin-top:139px;
  img{
    width: 276px;
    height: 70px;
  }
  &>div{
    flex:1;
    display: flex;
    padding: 0 16% 0 25%;
    align-items: self-start;
    color: #fff;
    justify-content: space-between;
    &>div:nth-of-type(1){
      display: flex;
      flex-direction: column;
      &>span:nth-of-type(1){
        font-size:20px;
        cursor: pointer;
      }
      &>span:nth-of-type(2){
        margin:25px 0;
        color: #949494;
        font-size: 16px;
        cursor: pointer;
      }
      &>span:nth-of-type(3){
        color: #949494;
        font-size: 16px;
        cursor: pointer;
      }
    }
    &>div:nth-of-type(2){
      display: flex;
      flex-direction: column;
      &>span:nth-of-type(1){
        font-size:20px;
        cursor: pointer;
      }
      &>span:nth-of-type(2){
        margin:12px 0;
        color: #949494;
        font-size: 16px;
        cursor: pointer;
      }
      &>span:nth-of-type(3){
        color: #949494;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
.img-wrap{
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: end;
  box-sizing: border-box;
  img{
    width:95%;
    height: 520px;
  }
  img:nth-of-type(1){
    margin-bottom: 15px;
  }
}
.exclusive-footer{
  padding: 0 200px 0 164px;
  display: flex;
  justify-content: space-between;
  margin-top: 110px;
  img{
    height: 868px;
    width: 379px;
    margin-right: 20px;
  }
  align-items: center;
  &>div{
    height: 638px;
    position: relative;
    span{
      position: absolute;
      left: 0;
      font-weight: bolder;
      font-size: 26px;
      top: 10px;
    }
    img{
      height: 683px;
      width: 611px;
    }
  }
}
.exclusive-wrap{
  margin-top: 100px;
}
.exclusive-middle{
  display: flex;
  padding: 0 164px;
  box-sizing: border-box;
  justify-content: space-between;
  div{
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    span{
      font-size: 16px;
      line-height: 32px;
      width: 606px;
    }
  }
  img{
    margin-top: -120px;
    width: 366px;
    height: 440px;
    border-radius: 8px;
  }
}
.exclusive-header{
  background: #D10B2E;
  padding: 74px 200px 0 164px;
  height: 320px;
  display: flex;
  justify-content: space-between;
  &>span{
    color: #fff;
    font-size: 36px;
    line-height: 70px;
    width: 329px;
  }
  &>img{
    width: 36px;
    height: 24px;
  }
}
.woman-wrap{
  display: flex;
  flex-direction: column;
  background: #F3F3F3;
  width: calc(100% / 3);
  &>div{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 96px 0 120px 0;
    justify-content: space-between;
    &>span:nth-of-type(1){
      margin-left: 42px;
      font-size: 16px;
    }
    &>span:nth-of-type(2){
      padding:0 42px;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
    }
  }
  img{
    width: 100%;
    height: 614px;
    display: block;
  }

}
.menswear-wrap{
  display: flex;
  flex-direction: column;
  background: #F3F3F3;
  width: calc(100% / 3);
  img{
    width: 100%;
    height: 614px;
    display: block;
  }
  &>div{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 96px 0 120px 0;
    &>span:nth-of-type(1){
      margin-left: 42px;
      font-size: 16px;
    }
    &>span:nth-of-type(2) {
      line-height: 32px;
      font-weight: 400;
      font-size: 16px;
      padding: 0 42px;
    }
  }

  &>span{
    font-size: 16px;
  }
  &>span:nth-of-type(1){
    margin-top: 96px;
  }
  &>span:nth-of-type(2){
    margin-top:76px;
    margin-bottom: 120px;
    padding:0 42px;
  }
}
.home{
  width: 100%;
}
.article-wrap{
  height: 1043px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  padding: 0 164px;
  margin-top: 109px;
  &>div:nth-of-type(3){
   width: calc(100% / 3);
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.customized-btn{
  cursor: pointer;
  background: #D10B2E;
  width: 120px;
  height: 38px;
  font-size: 18px;
  color:#fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-wrap{
  position: relative;
  img{
    height: 680px;
    width: 100%;
  }
  &>span:nth-of-type(1){
    position: absolute;
    top: 94px;
    left: 164px;
    line-height: 32px;
    width: 146px;
    font-size: 16px;
  }
  &>div:nth-of-type(1){
    display: flex;
    font-weight: bold;
    flex-direction: column;
    position: absolute;
    top: 251px;
    height: 135px;
    left: 164px;
    &>span,i{
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  &>div:nth-of-type(2){
    top: 527px;
    cursor: pointer;
    position: absolute;
    left: 164px;
    display: flex;
    align-items: center;
    span{
      color: #C4162F;
      font-weight: bold;
      font-size: 32px;
    }
    img{
      margin-left: 10px;
      width: 23px;
      height: 14px;
    }
  }
}
.logo-r{
  display: flex;
  align-items: center;
  &>div:nth-of-type(1){
      border-left: 1px dashed #807E89;
      height: 32px;
      margin-right: 24px;
  }
  &>div:nth-of-type(2){
    position: relative;
    display: flex;
    flex-direction: column;
    span{
      font-size: 16px;
      color:#807E89;
    }
  }
//  &>div::before{
//  position: absolute;
//  left:-24px;
//  content:"";
//  top: 8px;
//  border-left: 1px dashed #807E89;
//  height: 32px;
//}
}
.logo-wrap{
  display: flex;
  align-items: center;
  &>img{
    width: 242px;
    height:64px;
    margin-right: 48px;
  }
}
.header-wrap{
  box-sizing: border-box;
  padding: 0 100px;
  justify-content: space-between;
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
