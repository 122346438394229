import Vue from "vue";
import VueRouter from "vue-router";
import OfficialWebsite from "@/views/OfficialWebsite"
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash', // 使用 hash 模式
    routes: [
        {
            path: '/',
            name: 'OfficialWebsite',
            component: OfficialWebsite
        },
    ]
});

export default router;
